export function formatCurrency(units: number, precision: number) {
  return Number(units).toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: precision || 2,
    maximumFractionDigits: precision || 2,
  });
}

export function formatSnakeCase(value: any): string {
  return (
    value
      ?.match(
        /[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g,
      )
      ?.map((x: any) => x.toLowerCase())
      ?.join('_') || ''
  );
}

export function clamp(value: number, min: number, max: number) {
  if (value > max) {
    return max;
  }

  if (value < min) {
    return min;
  }

  return value;
}

export function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const shortMonths = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

export const equalityFilters = [
  'equals',
  'starts with',
  'ends with',
  'contains',
  'does not equal',
  'does not start with',
  'does not end with',
  'does not contain',
];
